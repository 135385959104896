import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PricingCard = ({
  name,
  price,
  old_price,
  features_detail,
  plan_type,
  description,
  invoice_period,
  syrianPrice,
}) => {
  const perDay = parseFloat((price / (invoice_period * 30)).toFixed(2));
  const { t } = useTranslation();

  return (
    <div className="relative p-4 md:p-6 bg-[#1C1B1E] rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:border-orange-500 border-4 border-transparent w-[92%] md:w-96">
      <div className="absolute w-[calc(100%+8px)] rounded-t-lg h-14 -top-1 -left-1 bg-orange-500 text-white text-2xl font-bold pt-2 text-center">
        {plan_type === "Most Popular" ? t("mostPopular") : t("standard")}
      </div>

      <div className="text-center pt-3">
        <h3 className="text-white text-xl md:text-3xl font-semibold mt-12">
          {name}
        </h3>
        <h3 className="text-white text-base mt-2">{description}</h3>

        <p className="text-orange-400 text-xl mt-2 md:mt-4">
          {features_detail}
        </p>

        <div className="flex items-start justify-center text-white mt-4 opacity-50">
          <sup className="text-sm">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-xl md:text-4xl line-through">{old_price}</span>
        </div>

        <div className="flex items-start justify-center text-white font-bold my-3 md:my-6">
          <sup className="text-base">{syrianPrice ? "$" : "SYR"}</sup>
          <span className="text-3xl md:text-[55px] leading-[3rem]">
            {price}
          </span>
        </div>
        <p className="text-orange-400 text-lg md:text-xl">
          {syrianPrice ? "$" : "SYR"} {perDay} {t("perDay")}
        </p>
        <div className="flex justify-center items-center mt-3">
          <Link
            to="https://t.me/DrMichelAdib?start=%D8%A7%D8%B4%D8%AA%D8%B1%D8%A7%D9%83"
            target="blank"
            className="bg-orange-500 capitalize font-semibold text-white text-[20px] py-2 px-5 rounded-3xl"
          >
            {t("subscribe")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
