import React, { useEffect, useState } from "react";
import phoneImage from "../../assets/images/phoneImage.png";
import phoneImgAr from "../../assets/images/phoneImageAR.png";
import Orangepal2 from "../../assets/images/Orangepal2.png";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("ar");
  const { t, i18n } = useTranslation();

  const isArabic = selectedLanguage === "ar";

  useEffect(() => {
    // Initialize language from localStorage if available
    const storedLng = localStorage.getItem("lng");
    if (storedLng) {
      setSelectedLanguage(storedLng);
      i18n.changeLanguage(storedLng);
    } else {
      setSelectedLanguage(selectedLanguage);
      localStorage.setItem("lng", selectedLanguage);
    }
  }, [i18n, i18n.language]);

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleLanguageChange = () => {
    const newLanguage = isArabic ? "en" : "ar";
    setSelectedLanguage(newLanguage);
    handleChangeLng(newLanguage); // Trigger the language change in your app
  };
  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-[#FF6400] to-[#FE8E22] text-white">
      <div className="flex justify-around xl:gap-x-96 pt-6">
        <div>
          <img
            src={Orangepal2}
            alt="Orangefit Logo"
            className="w-40 mb-8 md:w-48"
          />
        </div>
        <button
          className="cursor-pointer font-semibold -mt-12 text-2xl"
          onClick={handleLanguageChange}
        >
          {isArabic ? "English" : "عربي"}
        </button>
      </div>

      <div className="md:w-[85%] md:max-w-[1300px] mx-auto px-4 flex flex-col items-center md:flex-row md:justify-between">
        <div
          className={`text-center ${
            isArabic ? "md:text-end" : "md:text-start"
          } z-10`}
        >
          <h1 className="text-2xl md:text-6xl font-bold mb-2">{t("Reach")}</h1>
          <h2 className="text-2xl md:text-6xl mb-6 md:mb-12">{t("with")}</h2>
          <p
            dir={`${isArabic ? "rtl" : "ltr"}`}
            className={`${
              isArabic ? "" : "md:w-[75%]"
            } md:text-start mb-6 md:mb-12 text-lg opacity-85 font-normal md:font-semibold md:text-2xl`}
          >
            {t("Orangefit")}
          </p>
          <div
            className={`flex ${
              isArabic ? "md:justify-end" : "md:justify-start"
            } justify-center items-center`}
          >
            <a
              href="/#plans"
              className="bg-white font-semibold text-black text-[20px] py-2 px-6 rounded-xl"
            >
              {t("HeroBtn")}
            </a>
          </div>
        </div>
        <div className="z-10 mt-12 -mb-96 md:my-0">
          <img
            src={phoneImgAr}
            alt="Mobile App"
            className={`${!isArabic && "hidden"} w-80`}
          />
          <img
            src={phoneImage}
            alt="Mobile App"
            className={`${isArabic && "hidden"} w-80`}
          />
        </div>
        <div className="absolute z-0 bottom-[380px] md:-bottom-16 left-0 w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="w-full h-full"
          >
            <path
              fill="#151515"
              fillOpacity="1"
              d="M0,192L60,192C120,192,240,192,360,186.7C480,181,600,171,720,154.7C840,139,960,117,1080,112C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="bg-[#151515] h-96 z-50 md:hidden"></div>
    </section>
  );
};

export default HeroSection;
