import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CircleSection = () => {
  const { t, i18n } = useTranslation();

  const [percentage, setPercentage] = useState(25);
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  const isArabic = i18n.language === "ar";

  // Animation function
  const animateProgress = (start, end, duration) => {
    let current = start;
    const increment = (end - start) / (duration / 16); // Increment per frame (assuming 60fps)

    const interval = setInterval(() => {
      current += increment;
      if (current >= end) {
        current = end;
        clearInterval(interval);
      }
      setPercentage(current);
    }, 16); // Update every ~16ms (approx. 60fps)

    return () => clearInterval(interval);
  };

  // Trigger animation when in view
  React.useEffect(() => {
    if (inView) {
      animateProgress(25, 95, 2000); // Animate from 25% to 95% over 2 seconds
    }
  }, [inView]);

  const textAlignment = isArabic ? "text-end" : "text-start";

  const paragraphAlignment = isArabic
    ? "text-center md:text-start"
    : "text-center md:text-start";

  return (
    <section className="bg-gradient-to-br from-[#FF6400] to-[#FE8E22] text-white pb-20 pt-24 rounded-3xl">
      <motion.div
        ref={ref} // Attach ref to motion.div to observe the element
        className={`md:w-[85%] md:max-w-[1300px] flex flex-col md:flex-row w-full justify-evenly gap-y-8 gap-x-16 mx-auto px-5 items-center ${textAlignment}`}
        initial={{ opacity: 0, y: 20 }} // Initial animation state
        animate={inView ? { opacity: 1, y: 0 } : {}} // Animate when in view
        transition={{ duration: 0.5 }} // Animation duration
      >
        <div className="">
          <p
            dir={`${isArabic ? "rtl" : ""}`}
            className={`text-4xl font-light ${paragraphAlignment} md:text-6xl mb-6 md:mb-16 leading-tight`}
          >
            <span>{isArabic ? "95" : "“95"}%&nbsp;</span>
            <span className="inline ml-1">{t("individuals")}</span>{" "}
            <span className="inline"> {t("whoTryTo")}</span>
            <span className="inline">{t("loseWeight")}</span>
            <span className={`inline font-semibold ${isArabic ? "" : "ml-2"}`}>
              {t("fail")}
            </span>
          </p>
          <p
            dir={`${isArabic ? "rtl" : ""}`}
            className={`text-2xl md:text-2xl font-semibold ${paragraphAlignment} pl-1`}
          >
            {t("you")}
          </p>
        </div>

        <div className="mx-auto md:mt-0 md:w-96 md:min-w-90">
          <CircularProgressbar
            value={percentage}
            text={`${Math.round(percentage)}%`}
            styles={buildStyles({
              textSize: "20px",
              pathColor: "#fff",
              textColor: "#fff",
              trailColor: "#000",
              backgroundColor: "#ff7f00",
            })}
            className="w-full"
          />
        </div>
      </motion.div>
    </section>
  );
};

export default CircleSection;
