import React from "react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "./TextSlider.css";

// Import images
const imageSources = [
  require("../../assets/images/Frame2.jpg"),
  require("../../assets/images/Frame3.jpg"),
  require("../../assets/images/Frame4.jpg"),
  require("../../assets/images/Frame5.jpg"),
  require("../../assets/images/Frame6.jpg"),
  require("../../assets/images/Frame7.jpg"),
  require("../../assets/images/Frame8.jpg"),
  require("../../assets/images/Frame9.jpg"),
  require("../../assets/images/Frame10.jpg"),
];

const feedbackSources = [
  require("../../assets/images/Feedback.jpg"),
  require("../../assets/images/Feedback1.jpg"),
  require("../../assets/images/Feedback2.jpg"),
  require("../../assets/images/Feedback3.jpg"),
  require("../../assets/images/Feedback4.jpg"),
  require("../../assets/images/Feedback5.jpg"),
  require("../../assets/images/Feedback6.jpg"),
  require("../../assets/images/Feedback7.jpg"),
  require("../../assets/images/Feedback8.jpg"),
  require("../../assets/images/Feedback9.jpg"),
  require("../../assets/images/Feedback10.jpg"),
  require("../../assets/images/Feedback11.jpg"),
  require("../../assets/images/Feedback12.jpg"),
  require("../../assets/images/Feedback13.png"),
  require("../../assets/images/Feedback14.png"),
];

const NextArrow = ({ onClick }) => (
  <div
    className="absolute -bottom-12 md:-bottom-16 right-1/4 transform  z-10 cursor-pointer"
    onClick={onClick}
  >
    <span className="text-4xl md:text-6xl text-white hover:scale-110 transition-transform">
      <FaArrowAltCircleRight />
    </span>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute -bottom-12 md:-bottom-16 left-1/4 transform z-10 cursor-pointer"
    onClick={onClick}
  >
    <span className="text-4xl md:text-6xl text-white hover:scale-110 transition-transform">
      <FaArrowAltCircleLeft />
    </span>
  </div>
);

const TestimonialsSection = () => {
  const { t } = useTranslation();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <section className="bg-gradient-to-br from-[#FF6400] to-[#FE8E22] text-white pt-20 pb-40 rounded-3xl">
      <div className="md:w-[85%] md:max-w-[1300px] mx-auto">
        <div className="mx-auto px-4 flex flex-col text-center md:flex-row md:justify-between items-center">
          <h2 className="text-4xl md:text-6xl font-bold mb-8 md:mb-0">
            <span
              className="leading-snug"
              dangerouslySetInnerHTML={{ __html: t("orangefitBest") }}
            />
          </h2>
          <div className="w-11/12 md:w-3/5">
            <div className="flex justify-center gap-x-[30%] items-center mb-6">
              <p className="text-3xl md:text-4xl font-semibold">
                {t("before")}
              </p>
              <p className="text-3xl md:text-4xl font-semibold">{t("after")}</p>
            </div>
            <Slider {...sliderSettings} className="w-full">
              {imageSources.map((url, index) => (
                <div
                  key={index}
                  className="px-3 rounded-2xl flex justify-center items-center md:max-w-[630px] md:max-h-[620px]"
                >
                  <img
                    className="rounded-2xl object-contain w-full h-full"
                    src={url}
                    alt="Results Slider"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="text-center mt-28 md:mt-40 relative z-10">
          <p className="text-3xl md:text-5xl font-semibold mb-10">
            {t("clientTestimonail")}
          </p>
          <Slider {...sliderSettings} className="w-10/12 md:w-1/3 mx-auto">
            {feedbackSources.map((url, index) => (
              <div key={index} className="px-3">
                <img src={url} alt="Testimonials Slider" />
              </div>
            ))}
          </Slider>
          <img
            src={require("../../assets/images/circles.png")}
            className="w-full md:w-2/5 -z-10 absolute top-20 md:right-1/3 md:pl-20"
            alt="Circles Background"
          />
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
