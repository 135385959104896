import React, { useEffect, useState } from "react";
import PricingCard from "../PricingCard";
import { useTranslation } from "react-i18next";
import api from "../../api/api";

const PricingSection = () => {
  const [plansPrice, setPlansPrice] = useState([]);
  const [showSyrianPrice, setShowSyrianPrice] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    fetchPlans();
  }, [i18n.language]);

  const isArabic = i18n.language === "ar";

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const language = isArabic ? "ar" : "en"; // Determine the language
      const response = await api.get(`/admin/plans?paginate=100`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": language, // Set the language header
        },
      });
      setPlansPrice(response.data.data);
    } catch (error) {
      console.log("Error fetching plan!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section
        id="plans"
        className="relative z-20 bg-[#151515] text-white pt-20"
      >
        <div className="mx-auto md:w-[90%] px-6">
          <h2 className={"text-4xl md:text-5xl font-bold mb-20 text-center"}>
            {t("choosePlan")}
          </h2>

          <div
            className={`flex flex-col md:flex-row md:flex-wrap justify-center items-center md:items-end gap-x-12 gap-y-12`}
          >
            {plansPrice.length > 0 ? (
              plansPrice.map((item) => (
                <PricingCard
                  key={item.id}
                  name={item?.name}
                  price={showSyrianPrice ? item?.price : item?.syr_price}
                  features={t("dietOnly")}
                  Subscribe={t("Subscribe")}
                  plan_type={item?.plan_type}
                  description={item?.description}
                  features_detail={item?.features_detail}
                  old_price={
                    showSyrianPrice ? item?.old_price : item?.old_syr_price
                  }
                  invoice_period={item?.invoice_period}
                  syrianPrice={showSyrianPrice}
                />
              ))
            ) : (
              <>
                {loading && <p>{t("loading")}</p>}
                {!loading && <p>{t("errorMessage")}</p>}
              </>
            )}
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setShowSyrianPrice(!showSyrianPrice)}
              className="bg-orange-500 font-semibold text-white text-[20px] py-3 px-6 mt-10 rounded-3xl"
            >
              {showSyrianPrice ? t("syPrice") : t("usdPrice")}
            </button>
          </div>
        </div>
      </section>

      <div className="w-9/12 mx-auto mt-20 mb-10 h-1 bg-gradient-to-r from-transparent via-orange-500 to-transparent"></div>
    </>
  );
};

export default PricingSection;
