import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import api from "../../../api/api";

const EditSettings = ({ formData, onCancel, token, refresh }) => {
  const initialFormData = {
    id: null,
    type: "",
    value: [],
    value_en: [],
    value_ar: [],
    photos: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // New state for selected image

  // console.log("formValue", formData);
  useEffect(() => {
    if (formData) {
      setFormValues({
        id: formData.id || null,
        type: formData.type || "",
        value: formData.value || [],
        value_en: formData.value_en || [], // Keep value as is; it'll be handled below
        value_ar: formData.value_ar || [], // Keep value as is; it'll be handled below
        photos: formData.photos || [],
      });
    }
  }, [formData]);

  // Handle input changes for regular fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // console.log(name);

    // Handle array inputs if value is an array
    if (Array.isArray(formValues.value)) {
      // console.log("first");
      setFormValues((prevValues) => ({
        ...prevValues,
        value: prevValues.value.map((item, index) =>
          index === Number(name) ? value : item
        ),
      }));
    } else {
      // Handle object inputs if value is an object
      // console.log("secound");
      setFormValues((prevValues) => ({
        ...prevValues,
        value: {
          ...prevValues.value,
          [name]: value,
        },
      }));
    }
  };

  const handleEnglishInputChange = (e) => {
    const { name, value } = e.target;

    // console.log(name)

    // console.log("English");
    setFormValues((prevValues) => ({
      ...prevValues,
      value_en: prevValues.value_en.map((item, index) =>
        index === Number(name) ? value : item
      ),
      value: prevValues.value_en.map((item, index) =>
        index === Number(name) ? value : item
      ),
    }));
  };

  const handleArabicInputChange = (e) => {
    const { name, value } = e.target;

    // console.log(name)
    // console.log("Arabic");
    setFormValues((prevValues) => ({
      ...prevValues,
      value_ar: prevValues.value_ar.map((item, index) =>
        index === Number(name) ? value : item
      ),
    }));
  };

  // Handle image selection for breakfast_card_photo_link
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await api.post(`/admin/settings/${formValues.id}`, formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log(response);

      refresh(); // Refresh the settings in the parent component
      onCancel(); // Close the form
    } catch (error) {
      alert(`Error updating Settings`);
    } finally {
      setLoading(false);
    }
  };

  // Function to add a new input field
  const addNewInput = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      value_ar: [...prevValues.value_ar, ""], // Add an empty string for the new input
      value_en: [...prevValues.value_en, ""], // Add an empty string for the new input
    }));
  };

  // Function to delete an input field
  const deleteInput = (index) => {
    const newArValues = formValues.value_ar.filter((_, i) => i !== index);
    const newEnValues = formValues.value_en.filter((_, i) => i !== index);
    const newValues = formValues.value.filter((_, i) => i !== index);

    setFormValues((prevValues) => ({
      ...prevValues,
      value_ar: newArValues,
      value_en: newEnValues,
      value: newValues,
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] p-10 rounded-2xl shadow-lg shadow-slate-600 max-w-3xl w-full mt-10">
        <form
          className="mt-5 space-y-6 max-h-[80vh] overflow-auto custom-scrollbar pr-5"
          onSubmit={handleSubmit}
        >
          {/* Type field */}
          <div>
            <h2 className="text-white text-2xl mb-2 font-bold">English Type</h2>
            <input
              id="type"
              name="type"
              type="text"
              minLength="2"
              maxLength="25"
              required
              className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
              placeholder="Type"
              value={formValues.type}
              onChange={handleInputChange}
            />
          </div>
          {formValues.type === "privacy-policy" && (
            <div>
              <h2 className="text-white text-2xl mb-2 font-bold">
                Privacy Policy
              </h2>
              <textarea
                id="privacy-policy"
                name="value"
                rows="10"
                className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                placeholder="Enter privacy policy text"
                defaultValue={formValues.value || ""} // Handle value as array
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          )}
          {formValues.type === "breakfast_card_photo_link" && (
            <div>
              <h2 className="text-white text-2xl mb-2 font-bold">
                Upload Photo
              </h2>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
              />
              {/* Show the existing photo */}
              {formValues.photos.length > 0 && (
                <div className="mt-4">
                  <h3 className="text-white text-xl mb-2 font-bold">
                    Current Photo:
                  </h3>
                  <img
                    src={formValues.photos[0].url}
                    alt="Current breakfast card"
                    className="max-w-full h-auto"
                  />
                </div>
              )}
            </div>
          )}
          {formValues.type === "payment_info" && (
            <div>
              <div className="flex flex-col gap-y-2">
                <label className="text-white font-bold">Account Number:</label>
                <input
                  id="account_number"
                  name="account_number"
                  type="text"
                  className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                  placeholder="Account Number"
                  defaultValue={formValues.value.account_number || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-y-2 pt-2">
                <label className="text-white font-bold">Account Name:</label>
                <input
                  id="account_name"
                  name="account_name"
                  type="text"
                  className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                  placeholder="Account Name"
                  defaultValue={formValues.value.account_name || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col gap-y-2 pt-2">
                <label className="text-white font-bold">Phone Number:</label>
                <input
                  id="phone_number"
                  name="phone_number"
                  type="text"
                  className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                  placeholder="Phone Number"
                  defaultValue={formValues.value.phone_number || ""}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          {formValues.type === "payments_info_v2" && (
            <>
              {formValues.value_en.map((val, index) => (
                <div key={index}>
                  <div className="flex flex-col gap-y-2">
                    <label className="text-white font-bold">
                      Account Number:
                    </label>
                    <input
                      id="account_number"
                      name="account_number"
                      type="text"
                      className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      placeholder="Account Number"
                      defaultValue={val["Payment Details"] || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 pt-2">
                    <label className="text-white font-bold">
                      Account Name:
                    </label>
                    <input
                      id="account_name"
                      name="account_name"
                      type="text"
                      className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      placeholder="Account Name"
                      defaultValue={val["Account holder's name"] || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 pt-2">
                    <label className="text-white font-bold">
                      Phone Number:
                    </label>
                    <input
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      placeholder="Phone Number"
                      defaultValue={val["Phone Number"] || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 pt-2">
                    <label className="text-white font-bold">
                      Payment Details:
                    </label>
                    <input
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      placeholder="Phone Number"
                      defaultValue={val["Payment Details"] || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <hr className="my-6" />
                </div>
              ))}
            </>
          )}
          {Array.isArray(formValues.value) &&
            formValues.type !== "payments_info_v2" && (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h2 className="text-white text-2xl mb-2 font-bold">
                    English Values
                  </h2>
                  {formValues.value_en.map((item, index) => (
                    <div key={uuidv4()} className="flex space-x-2 mb-2">
                      <input
                        type="text"
                        defaultValue={item}
                        onChange={(e) => handleEnglishInputChange(e)}
                        name={index} // Use index for array handling
                        className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      />
                    </div>
                  ))}
                  {formValues.value.length === 0 && (
                    <p className="text-white text-xl font-semibold my-3">
                      No items yet
                    </p>
                  )}
                  <button
                    type="button"
                    onClick={addNewInput}
                    className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-2"
                  >
                    Add New Input
                  </button>
                </div>
                <div>
                  <h2 className="text-white text-2xl mb-2 font-bold">
                    Arabic Values
                  </h2>
                  {formValues.value_ar.map((item, index) => (
                    <div key={uuidv4()} className="flex space-x-2 mb-2">
                      <input
                        type="text"
                        defaultValue={item}
                        onChange={(e) => handleArabicInputChange(e)}
                        name={index} // Use index for array handling
                        className="w-full px-4 py-2 bg-[#202023] text-white border-gray-500 rounded-lg focus:outline-none"
                      />
                      <button
                        type="button"
                        onClick={() => deleteInput(index)}
                        className="bg-red-500 text-white rounded-lg px-4 py-2"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

          {/* Submit and Cancel buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSettings;
