import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import CardDataStats from "./CardDataStats";
import api from "../../../api/api";
import Loader from "../../../components/Loader/Loader";
import ChartCircle from "./ChartCircle";
import Chart from "./Chart";

const Analytics = () => {
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true); // Added loading state
  const token = localStorage.getItem("authenticatedToken");

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatus();
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await api.get("/admin/reports/subscription-status", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      setStatus(response.data.data);
    } catch (error) {
      console.log("Error fetching user");
      // alert("Error fetching user");
    }
  };

  if (loading) {
    return <Loader />; // Show loader while data is being fetched
  }
  return (
    <>
      <div className="grid grid-cols-1 px-3 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5 mt-10">
        <CardDataStats title="Total Subscriptions" total={status?.users_count}>
          <FaUser />
        </CardDataStats>
        <CardDataStats
          title="Active Subscriptions"
          total={status?.users_with_active_subscriptions}
        >
          <FaUser />
        </CardDataStats>
        <CardDataStats
          title="Renewed Subscriptions"
          total={status?.users_with_renewed_subscriptions}
        >
          <FaUser />
        </CardDataStats>
      </div>
      <div className="mt-2 mb-8 px-3 grid grid-cols-9 gap-4 md:mt-6 md:gap-2 2xl:mt-20 2xl:gap-5">
        <ChartCircle />
        <Chart />
      </div>
      <div></div>
    </>
  );
};

export default Analytics;
